<template>
  <div class="canna_start_pageZone">
    <div class="canna_login_zone"
      :style=" 
        (hasVerificaOption===true) ? 
          'flex-direction: column;justify-content: center;' + ( (is_Mobile || $q.screen.lt.md) && currentView==='main' ? 'flex-direction: column;' : '' ) 
          : 
          '' + ( (is_Mobile || $q.screen.lt.md) && currentView==='main' ? 'flex-direction: column;' : '' )
      "
      :class="{
        'canna_login_zoneIntro':( (is_Mobile || $q.screen.lt.md) && currentView==='main') ? false:true 
      }"
    >
      <!-- Zona de login -->

        <template v-if="(is_Mobile || $q.screen.lt.md)">
          <div class="login_intro_explainer noselect">
            <div class="main_intro">
              ENCUENTRA EL <template v-if="$q.screen.lt.sm || $q.screen.gt.sm"><br></template> 
              <span class="main_introAlt">MEJOR TRABAJO</span>
            </div>
            <div class="explainer">
              <span class="explainerAlt">¡Bienvenido a la bolsa de trabajo Canna!</span> 
              <template v-if="$q.screen.lt.sm || $q.screen.gt.sm"><br></template> Aquí encontrarás las mejores ofertas laborales en marcas exclusivas.
            </div>
          </div>
        </template>
        <template v-else>
          <div class="login_intro_explainer_layer noselect">

            <div class="login_intro_explainer noselect">
              <div class="main_intro">
                ENCUENTRA EL <template v-if="$q.screen.lt.sm || $q.screen.gt.sm"><br></template> <span class="main_introAlt">MEJOR TRABAJO</span>
              </div>
              <div class="explainer">
                <span class="explainerAlt">¡Bienvenido a la bolsa de trabajo Canna!</span> 
                <template v-if="$q.screen.lt.sm || $q.screen.gt.sm"><br></template> Aquí encontrarás las mejores ofertas laborales en marcas exclusivas.
              </div>
            </div>

            <div class="login_intro_explainer_image noselect">
              <img class="noselect" draggable="false" src="images/intro_canna_001.png"
                :style="(pageHeight <= 621) ? 'height: 300px;' : (pageHeight > 621 && pageHeight <669) ? 'height: 363px;' : '' "
              >
              <!--
                :style="($q.screen.gt.xs && $q.screen.lt.md)? 'max-width: 533px;' :''"
              -->
            </div>

          </div>
        </template>

        <div class="login_intro_form_layer">

          <template v-if="hasVerificaOption===true">
            <div class="login_container_titleAlt main_title" style="margin-top: 0px; margin-bottom: 20px;">
              <!-- style="margin-top: 76px;" -->
              Tu correo electrónico ha sido verificado
            </div>
          </template>
          <div id="login_container_frame" class="login_container_frame" 
            :style=" (hasVerificaOption===true) ? 
              'margin: 0px auto;' + ( (is_Mobile || $q.screen.lt.md) ?  'margin: auto;' : 'margin-top: 20px;') : 
                '' + ( (is_Mobile || $q.screen.lt.md) ?  'margin: auto;' : 'margin-top: 0px;')
            "
          >
            <template v-if="hasVerificaOption===false">
              <div class="main_title">
                Iniciar sesión
              </div>
              <div class="main_subtitle" style="margin-top: 6px;">
                ¿Eres nuevo en Canna? <span class="main_actionText action_item" @click.stop="goTonewAccountPage">Crea una cuenta</span>
              </div>
            </template>

            <div class="canna_formZone">

                <ValidationObserver ref="formCannaLogin" v-slot="{ handleSubmit, valid, passed }">
                  <q-form
                    ref="myLoginForm"
                    @submit.prevent="handleSubmit(onLoginSubmit)"
                    @reset="onLoginReset"
                    class="canna_forma_zona"
                    style=""
                  >
                    <!-- Campo de email -->
                    <ValidationProvider name="email" :immediate="false" rules="required|email|emojiFree|max:255" 
                      v-slot="{ errors, invalid, validated  }"
                    >
                      <div class="canna_forma_campo_container">
                        <div style="display: flex;justify-content: space-between;">
                          <div class="fieldLabel text-left"
                            :class="{
                              'fieldError':invalid && validated
                            }"
                          >Correo electrónico</div>
                          <div class="requiredLabel text-right" >
                            Obligatorio
                          </div>
                        </div>
                        <q-input
                          filled
                          name="email"
                          type="email"
                          v-model.trim="loginData.email"
                          hint=""
                          :dense="true"
                          :label="(loginData.email==='')?'ejemplo@email.com':''"
                          :error="invalid && validated"
                          :error-message="(errors[0] !==undefined) ? errores.email : ''"
                          :disabled="processingData"
                          @input="val => { loginData.email = ('' + val).trim()} "
                        />
                        <!--
                          :error-message="(errors[0] !==undefined) ?  errors[0].replace('Email','Correo electrónico').replace('El campo','El') : ''"
                        -->
                      </div>
                    </ValidationProvider>

                    <!-- Campo de password -->
                    <ValidationProvider name="password" :immediate="false" 
                      rules="required|emojiFree"
                      v-slot="{ errors, invalid, validated  }"
                    >
                      <div class="canna_forma_campo_container" style="margin-top: 26px;">
                        <div style="display: flex;justify-content: space-between;">
                          <div class="fieldLabel text-left"
                            :class="{
                              'fieldError':invalid && validated
                            }"
                          >Contraseña</div>
                          <div class="requiredLabel text-right" >
                            Obligatorio
                          </div>
                        </div>
                        <q-input
                          filled
                          :type="(screen_pass===true) ? 'password' : 'text'"
                          name="password"
                          v-model="loginData.password"
                          hint=""
                          :dense="true"
                          :label="(loginData.password==='')?'Escribe tu contraseña':''"
                          :error="(loginData.password!=='')? false : invalid && validated"
                          :error-message="(errors[0] !==undefined) ? '' : ''"
                        >
                          <!--
                            |min:8|max:12
                            :disabled="processingData"
                            (errors[0] !==undefined) ? errores.password : ''
                            cannaTextPassword
                            :error-message="(errors[0] !==undefined) ?  errors[0].replace('password','La Contraseña').replace('El campo','') : ''"
                            :error-message="(errors[0] !==undefined) ? errores.password : ''"
                            :error-message="(errors[0] !==undefined) ?  errors[0].replace('PassWord','Contraseña').replace('El campo','La') : ''"
                          -->
                          <template v-slot:append>

                            <div class="inputLabelAction noselect action_item" 
                              @click.stop="screen_pass = !screen_pass" 
                              v-show="(loginData.password.length>=1) ? true : false "
                            >
                              {{(screen_pass===true) ? 'MOSTRAR' : 'OCULTAR'}}  <!-- <q-icon name="r_star" /> -->
                            </div>

                          </template>
                        </q-input>
                      </div>
                    </ValidationProvider>

                    <template v-if="(frmMainErrors.pasoLogin !== '')">
                      <div class="canna_frmMainErrorZone spacerRowTop24" style="display: flex;flex-direction: row;align-items: center;">
                        <div style="margin-right:10px;">
                          <img src="images/login_warning.png"/>
                        </div>
                        <div style="flex:1;" v-html="frmMainErrors.pasoLogin"></div>
                      </div>
                    </template>

                    <div class="buttonZone" style="margin-top: 40px;">
                      <q-btn unelevated  label="Iniciar sesión" type="submit" class="buttonSave" color="app_mainBtn" :disabled="processingData" 
                        :style="'width:100%;'"
                        :disable="(passed || valid )? false : true"
                      />
                    </div>

                  </q-form>
                </ValidationObserver>

            </div>
            <div class="main_subtitle main_actionText action_item spacerRowTop24" @click.stop="goToRecoverPassPage"> <!--  currentView = 'reset' -->
              ¿Olvidaste tu contraseña?
            </div>
          </div>

        </div>

        <template v-if="(is_Mobile || $q.screen.lt.md)?true:false">
          <div class="noselect" style="margin-top: 64px;"
            :style=" (is_Mobile || $q.screen.gt.xs && $q.screen.lt.md) ? 'text-align: center;' : ''"
          >
            <img class="noselect login_intro_imageWelcome" src="images/intro_canna_001.png"
              style="width: 100%;"
              :style="($q.screen.gt.xs && $q.screen.lt.md)? 'max-width: 533px;' :''"
            > 
          </div>
        </template>

    </div>

    <q-inner-loading :showing="loading_Process" class="app_innerLoader" style="top: -51px;z-index: 9000;">
      <q-spinner-orbit size="100px" color="indigo" />
    </q-inner-loading>

  </div>
</template>
<script>
import { requestPack } from './../../boot/axios' // APP code 
import { canna_cookieMethods } from './../../boot/__cannav2_app'

import logOut_Mixin from './../../mixins/requestMix.js'
import myplatform_Mixin from './../../mixins/platformMix.js'
import helperBase_Mixin from './../../mixins/helperBase.js'

const cookieTestOptions = { expires: '1d', path: '/', httpOnly: false, sameSite:'lax', secure: false, appName: 'CannaV2PublicEmail' }
 
export default {
  name: 'canna_login_screen',
  mixins:[
    helperBase_Mixin,
    logOut_Mixin,
    myplatform_Mixin
  ],
  components:{

  },
  data () {
    return {
      version: '1.0.1',
      debugEls: false,
      zinitScreen: false,
      zloadingData: false,
      processingData: false,
      loading_Process: false,
      currentView: 'main', // 'main' /login  ||  'reset'
      screen_pass: true, //para controlar el mostrar ocultar en el input
      loginData: {
        email: '', 
        password: ''
      },
      resetData: {
        email: ''
      },
      errores:{
        email: 'El dato ingresado no tiene el formato de un correo electrónico. Por favor, revisa e inténtalo de nuevo.', 
        password: 'La contraseña no cumple con los requisitos mínimos.', //Por favor, revisa las indicaciones e inténtalo de nuevo.
        passwordAlt: 'La contraseña no cumple con los requisitos mínimos. Por favor, revisa las indicaciones e inténtalo de nuevo.',
      },
      frmMainErrors:{
        pasoLogin: '',
        pasoForgot: ''
      },
      frmMainRefs:{
        pasoLogin: [
          {fieldNameAPi: 'email', fieldNameForm: 'email' },
          {fieldNameAPi: 'password', fieldNameForm: 'password' }
        ],
        pasoForgot: [
          {fieldNameAPi: 'email', fieldNameForm: 'emailreset' }
        ]
      }
    }
  },
  computed: {
    hasVerificaOption_test () {
      return this.$store.state.myapp.appVerifyEmail
    },
    hasVerificaOption () {
      var myObj = this.$store.state.myapp.appVerifyEmail
      var myResp =  (myObj.action === true  && myObj.action_done === true && myObj.errors===false ) ? true : false 
      return myResp
    },
    layoutParent () {
      return this.$parent.$parent.$parent
    }
  },
  watch: {
    pageWidth: function (newVal, oldVal) { 
      if (newVal!==oldVal){ 
        this.$forceUpdate()
      }
    },
    currentView : function (newVal, oldVal) { 
      // WIP
      if (newVal!==oldVal){ 
        if (newVal==='main'){ 

          this.onPassReset()
          if (this.$refs.formResetPass) {
            this.$refs.formResetPass.reset()
          }
          this.layoutParent.$set(this.layoutParent, 'headLoginW','464px')
        } else if (newVal==='reset'){ 
					this.currentView = 'main '
          // this.onLoginReset()
          // if (this.$refs.formCannaLogin) {
          //   this.$refs.formCannaLogin.reset()
          // }
          // this.layoutParent.$set(this.layoutParent, 'headLoginW','')
        }
        this.$forceUpdate()
      }
    },
    loading_Process:function (newVal, oldVal) { 
      if (newVal!==oldVal){ 
        this.processingData =newVal
        this.$forceUpdate()
      }
    },
    'loginData.password': function (newVal, oldVal) {
      if (newVal!==oldVal){ 
        if (newVal==='' || newVal===null){
          this.screen_pass = true
        }
      }
    }
  },
  methods: {
    ...requestPack,
    ...canna_cookieMethods,
    test () { 
      this.$cannaDebug('-- loginscreen -- test')
    },
    goTonewAccountPage () {
      var newRuta = '/nuevacuenta'
      this.$router.push(newRuta)
    },
		goToRecoverPassPage (){ 
			var newRuta = '/recuperar-acceso'
			this.$router.push(newRuta)
		},
    onLoginReset () {
      this. loginData = { email: '',  password: '' },
      this.frmMainErrors.pasoLogin = ''
      this.$forceUpdate()
    },
    async onLoginSubmit () {
      this.$cannaDebug('-- loginscreen -- onLoginSubmit')
      var test = 11 
      var mloginModel = this.getModelRequest('_userlogin')
      var mpModel = this.getModelRequest('userProfile')
      var reqRes = { login: [], profile: [] }
      var reqErr = { login: 0, profile: 0 }
      // ------------------
      this.loading_Process = true
      // ------------------
      var myLogConfig = this.createAxiosConfig ('post', mloginModel, { 
        useData: true ,
        data: {
          grant_type: 'password',
          username: '' + this.loginData.email,
          password: '' + this.loginData.password
        } 
      }, false, null)
      // ------------------
      await this.doRequestByPromise(this.$axios, 'userlogin', reqRes.login, myLogConfig).then((resx) => {
        // console.log ('Action -- Carga de Clientes -- GET ejecutado')
        var tmpresponseData = reqRes.login[reqRes.login.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.login += 1
        }
      })
      if (reqErr.login > 0) { // Si hay error de server, session o algo redireccionamos..!
        test = 55
        // handleError
        this.ex_handlerErrorFormNoSessionRequest (reqRes.login[0], reqErr.login, this.$refs.formCannaLogin, 'formCannaLogin',  this.frmMainRefs.pasoLogin , 'pasoLogin', false)
        this.loading_Process = false
        return false
      }
      await this._waitRequestProc(100)
      // ------------------
      var loginData = reqRes.login[0].data.data
      var myToken = loginData.access_token
      this.$store.commit('myapp/setloginData', { token: myToken }) //setloginData
      this.$store.commit('myapp/setAppUserData', { access_token: myToken })
      await this._waitRequestProc(100)
      // ------------------
      // Hasta aqui estamos bien y obtenemos los datos de profile 
      var myProfileConfig = this.createAxiosConfig ('get', mpModel, { test: 11, expand:'experienciaLaboral,userPerfilLaboralFiltro' }, true, null)
      await this.doRequestByPromise(this.$apiV2, 'userlogin', reqRes.profile, myProfileConfig).then((resx) => {
        // console.log ('Action -- Carga de Clientes -- GET ejecutado')
        var tmpresponseData = reqRes.profile[reqRes.profile.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.profile += 1
        }
      })
      if (reqErr.profile > 0) { // Si hay error de server, session o algo redireccionamos..!
        this.$cannaDebug('-- loginscreen -- Error al cargar el profile -- ')
        this.loading_Process = false
        return false
      }
      var profileData = reqRes.profile[0].data.data
      this.$store.commit('myapp/setMainState',{ appUserProfile: profileData })
      await this._waitRequestProc(200)
      // ------------------
      // update cookie DATA
      var dataObject = { 
        logIn:true,
        user: profileData.id,
        token: myToken
      }
      var testCks = this.cannack_createCookie (this.$q.cookies, 'CannaV2Public', dataObject, true)
      // var testCks = this.cannack_setCookieData (this.$q.cookies, dataObject) Se reemplaza para inicializar la cookie 
      this.$cannaApiService.$cannaAxios.defaults.headers.common.Authorization = `Bearer ${loginData.access_token}`
      this.$cannaDebug('-- loginscreen -- ck created && updated -- ' + testCks)
      test = 77
      // ------------------
      this.loading_Process = false
      this.$forceUpdate()
      // Verificamos el estatus de la persona 
      var newRuta = ''
      if (profileData.status >=10){ // if (profileData.status >=12){  // cambio ya en >=10  ya entra al dashboard 
        newRuta= '/dashboard'
      } else { // Entonces el estatus es 10 u 11 
        newRuta = '/' //'/nuevacuenta'
      }
      this.$router.push(newRuta)
      return true
    },
/*

    async onResetPassSubmit() {
      this.$cannaDebug('-- loginscreen -- onResetPassSubmit')
      var test = 11
      var mpModel = this.getModelRequest('passForgot')
      var reqRes = { action: [], profile: [] }
      var reqErr = { action: 0, profile: 0 }
      // ------------------
      this.loading_Process = true
      // ------------------
      var myResetConfig = this.createAxiosConfig ('post', mpModel, {
        urlmixData:{
          notifier:'email',
          identifier: this.resetData.email
        },
      }, false, null)
      // ------------------
      await this.doRequestByPromise(this.$axios, 'resetLogin', reqRes.action, myResetConfig).then((resx) => {
        // console.log ('Action -- Carga de Clientes -- GET ejecutado')
        var tmpresponseData = reqRes.action[reqRes.action.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.action += 1
        }
      })
      if (reqErr.action > 0) { // Si hay error de server, session o algo redireccionamos..!
        test = 55
        // handleError
        this.ex_handlerErrorFormNoSessionRequest (reqRes.action[0], reqErr.action, this.$refs.formResetPass, 'formResetPass',  this.frmMainRefs.pasoForgot , 'pasoForgot', false)
        this.loading_Process = false
        return false
      }
      await this._waitRequestProc(1000)
      // ------------------
      // Todo bien no hubo errores
      var MSGTxt = 'Las instrucciones para restablecer tu contraseña han sido enviadas.'

      this.$q.notify({
        position: 'top',
        color:'cannaOKAlert',
        type: 'positive',
        message: MSGTxt,
        timeout: 7000 
      })

      setTimeout(() => {
        this.loading_Process = false
        this.currentView  = 'main' 
        this.$forceUpdate()
      }, 3500)

      return true
    },
    onPassReset () {
      this.resetData.email = ''
      this.frmMainErrors.pasoForgot = ''
      this.$forceUpdate()
    }
*/
  },
  beforeCreate () {
    this.$cannaDebug('-- loginscreen -- beforeCreate')
  },
  created () {
    this.$cannaDebug('-- loginscreen -- created')
    this.zloadingData = true 
    this.$q.iconSet.field.error = 'r_report_problem'
    this.loginData = { email: '', password: '' }
    this.resetData = { email: '' }
    this.frmMainErrors = { pasoLogin: '', pasoForgot: '' }
    this.currentView = 'main'
    this.layoutParent.$set(this.layoutParent, 'headLoginW','464px')
  },
  beforeMount () {
    // this.$cannaDebug('-- loginscreen --  beforeMount')
  },
  async mounted () {
    this.$cannaDebug('-- loginscreen -- mounted')
    this.zloadingData = false
  },
  beforeUpdate () {
    // this.$cannaDebug('-- loginscreen -- beforeUpdate')
  },
  updated () {
    // this.$cannaDebug('-- loginscreen -- updated')
  },
  activated () {
    this.$cannaDebug('-- loginscreen -- activated')
    this.loginData = { email: '', password: '' }
    this.resetData = { email: '' }
    this.frmMainErrors = { pasoLogin: '', pasoForgot: '' }
    this.currentView = 'main'
    if (this.$refs.formCannaLogin) {
      this.$refs.formCannaLogin.reset()
    }
    this.$forceUpdate()
  },
  deactivated () {
    // this.$cannaDebug('-- loginscreen -- deactivated')
  },
  beforeDestroy () {
    // this.$cannaDebug('-- loginscreen -- beforeDestroy')
  },
  destroyed () {
    // this.$cannaDebug('-- loginscreen -- destroyed')
  }
}
</script>